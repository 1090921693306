<template>
  <div>
    <a
      href="javascript:void(0)"
      @click="baixarArquivoModelo()"
    >
      Clique aqui
    </a>
    para baixar o arquivo modelo.
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
  </div>
</template>

<script>
import ConciliacoesService from '@/services/ConciliacoesService.js';

export default {
  props: {
    tipoConciliacao: {
      type: String,
      required: true
    }
  },

  data: () => ({
    loading: false
  }),

  methods: {
    baixarArquivoModelo() {
      this.loading = true;

      ConciliacoesService.baixarArquivoModelo(this.tipoConciliacao)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Modelo Conciliação ${this.tipoConciliacao}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$toast.error('Erro ao baixar arquivo modelo', '', {
            position: 'topRight'
          });
          console.error(err);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
